import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
var Home = function () {
    return (React.createElement(Container, { fluid: "lg pb-4" },
        React.createElement(Row, { className: "mt-4 align-items-center animate height" },
            React.createElement(Col, { xs: 12, sm: 6, className: "p-4 image-align" },
                React.createElement(Image, { fluid: true, roundedCircle: true, src: "images/fleming-photo.webp", className: "profile border border-4 border-light shadow-lg" })),
            React.createElement(Col, { sm: 6 },
                React.createElement(Row, { className: "align-items-center text-light" },
                    React.createElement(Col, { className: 'open-sans' },
                        React.createElement("p", { className: 'fs-4 mb-1' }, "\uD83D\uDC4B Hi there! I'm"),
                        React.createElement("h1", { className: "oswald mb-2 title-text" }, "Derrick Fleming"),
                        React.createElement("p", { className: 'fs-4 mb-0' },
                            "Software Developer who loves all things",
                            React.createElement("span", { className: 'fst-italic fs-4 block' }, " web development")),
                        React.createElement(Link, { className: 'mt-2 me-4 btn btn-primary btn-navy animate glow delay-1 fs-5 shadow-sm mb-4', to: "/projects" }, "Projects"),
                        React.createElement(Link, { className: "mt-2 btn btn-light animate glow delay-2 fs-5 shadow-sm mb-4", to: "/about" }, "About Me")))))));
};
export default Home;
