import React from 'react';
import Home from './pages/home';
import Projects from './pages/projects';
import AboutMe from './pages/about-me';
import NavigationBar from './components/nav';
import Contact from './pages/contact';
import { Routes, Route } from 'react-router-dom';
var App = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(NavigationBar, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Home, null) }),
            React.createElement(Route, { path: "/projects", element: React.createElement(Projects, null) }),
            React.createElement(Route, { path: "/about", element: React.createElement(AboutMe, null) }),
            React.createElement(Route, { path: "/contact", element: React.createElement(Contact, null) }))));
};
export default App;
