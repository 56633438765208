import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
var Contact = function () {
    return (React.createElement(Container, { fluid: "lg animate text-light" },
        React.createElement(Row, { className: "my-4 align-items-center height" },
            React.createElement(Col, { className: "ms-4 text-center" },
                React.createElement("div", null,
                    React.createElement("h1", { className: "oswald" }, "Contact")),
                React.createElement("p", { className: "fs-4" }, "Reach out and connect with me:"),
                React.createElement("a", { className: "btn btn-primary btn-navy me-2 animate glow delay-1 fs-5", href: "https://www.linkedin.com/in/derrick-fleming/" },
                    React.createElement("span", { className: "fa-brands fa-linkedin me-2" }),
                    "LinkedIn"),
                React.createElement("a", { className: "btn btn-primary btn-navy me-2 animate glow delay-2 fs-5", href: "https://github.com/derrick-fleming" },
                    React.createElement("span", { className: "fa-brands fa-github me-2" }),
                    "Github")))));
};
export default Contact;
