import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
var Projects = function () {
    return (React.createElement(Container, { fluid: "lg animate" },
        React.createElement(Row, { className: "mt-4 my-2 text-light align-items-center" },
            React.createElement("div", null,
                React.createElement("h1", { className: "ps-4 oswald" }, "Projects"),
                React.createElement("p", { className: "ps-4 fs-4 open-sans" }, "Check out some of my latest projects that I have developed and completed."))),
        React.createElement(Row, { className: "py-2 justify-content-center align-items-center" },
            React.createElement(Col, { xs: 11, sm: 6, md: 6, xl: 5, className: "animate glow delay-2 px-2" },
                React.createElement(Card, { className: "mb-4 shadow-lg border border-5 border-navy bg-blue" },
                    React.createElement(Card.Img, { variant: "top", src: "images/zion-stock.webp", className: 'vertical' }),
                    React.createElement(Card.Body, { className: "text-dark" },
                        React.createElement("h1", { className: "oswald" }, "Park Advisor"),
                        React.createElement("p", { className: "fs-5 open-sans" }, "A full stack web application for travelers who want to review and track their visits to different national parks.")),
                    React.createElement(Card.Footer, { className: "open-sans border-secondary" },
                        React.createElement("a", { className: "btn btn-light me-2 my-2 fs-5", href: "https://github.com/derrick-fleming/park-advisor" },
                            React.createElement("i", { className: 'fa-brands fa-github pe-1' }),
                            "Code"),
                        React.createElement("a", { className: "btn btn-primary btn-navy me-2 my-2 fs-5", href: "http://park-advisor.derrickfleming.com/" },
                            React.createElement("i", { className: "fa-solid fa-globe pe-1" }),
                            "Website")))),
            React.createElement(Col, { xs: 11, sm: 6, md: 6, xl: 5, className: "animate glow delay-3" },
                React.createElement(Card, { className: "mb-4 shadow-lg border border-5 border-navy bg-blue" },
                    React.createElement(Card.Img, { variant: "top", src: "images/animal-crossing.webp", className: 'vertical' }),
                    React.createElement(Card.Body, { className: "text-dark" },
                        React.createElement("h1", { className: "oswald" }, "MyVillagers"),
                        React.createElement("p", { className: "fs-5 open-sans" }, "A web application for Animal Crossing fans who want to view and keep notes on their favorite villagers.")),
                    React.createElement(Card.Footer, { className: "open-sans border-secondary border-2" },
                        React.createElement("a", { className: "btn btn-light fs-5 me-2 my-2", href: "https://github.com/derrick-fleming/my-villagers" },
                            React.createElement("i", { className: 'fa-brands fa-github pe-1' }),
                            "Code"),
                        React.createElement("a", { className: "btn btn-primary btn-navy fs-5 me-2 my-2", href: "https://derrick-fleming.github.io/my-villagers/" },
                            React.createElement("i", { className: "fa-solid fa-globe pe-1" }),
                            "Website")))))));
};
export default Projects;
