import React from 'react';
import Container from 'react-bootstrap/Container';
var AboutMe = function () {
    return (React.createElement(Container, { fluid: "lg animate text-light height" },
        React.createElement("div", null,
            React.createElement("h1", { className: "mt-4 ms-4 oswald" }, "About Me"),
            React.createElement("p", { className: "ms-4 mt-2 ps-4 fs-5 lh-lg animate" }, "I'm a software developer with skills in JavaScript, React, Node.js, CSS, and HTML. As a former educator and instructional designer, I'm drawn to being able to problem solve and think creatively through programming. I love being able to improve and grow in my skills and am always striving to learn each day. My goal is to create applications that can provide a meaningful and positive impact on the community."),
            React.createElement("p", { className: "fs-4 text-center" }, "- - ~ - -")),
        React.createElement("h1", { className: "ms-4 animate delay-3 oswald mb-3" }, "Stack"),
        React.createElement("ul", { className: "ps-4 lh-lg animate delay-3 fs-5" },
            React.createElement("li", { className: "ps-4 pb-2" },
                "\uD83D\uDCBB ",
                React.createElement("span", { className: 'fw-bold fst-italic ps-2' }, "Languages:"),
                " JavaScript, HTML5, CSS3, SQL, TypeScript"),
            React.createElement("li", { className: "ps-4 pb-2" },
                "\uD83D\uDCDA ",
                React.createElement("span", { className: "fw-bold fst-italic ps-2" }, "Libraries/Frameworks: "),
                "React, Node.js, Express.js, Bootstrap"),
            React.createElement("li", { className: "ps-4 pb-2" },
                "\uD83E\uDDF0 ",
                React.createElement("span", { className: "fw-bold fst-italic ps-2" }, "Tools: "),
                "Git, Github, Docker, Figma, Visual Studio Code, NPM, Babel, Webpack"))));
};
export default AboutMe;
