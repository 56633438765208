import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scroll-top';
var container = document.querySelector('#root');
var root = ReactDOM.createRoot(container);
root.render(React.createElement(React.Fragment, null,
    React.createElement(BrowserRouter, null,
        React.createElement(ScrollToTop, null),
        React.createElement(App, null))));
