import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
var NavigationBar = function () {
    return (React.createElement(Container, { fluid: "lg" },
        React.createElement(Navbar, { collapseOnSelect: true, expand: "md", className: "justify-content-between px-2 border-bottom border-3" },
            React.createElement(Nav.Link, { eventKey: "1", as: Link, className: "oswald fs-2 link-light", to: '/' }, "Derrick Fleming"),
            React.createElement(Navbar.Toggle, { "aria-controls": "offcanvasNavbar-expand-md", className: "border-0" }),
            React.createElement(Navbar.Offcanvas, { "aria-labelledby": "offcanvasNavbarLabel-expand-md", placement: "end", id: "offcanvasNavbar-expand-md", style: { maxWidth: '75%' }, className: "off-canvas-background" },
                React.createElement(Offcanvas.Header, { closeButton: true, className: "border-light border-bottom border-2" },
                    React.createElement(Offcanvas.Title, { className: "text-light oswald fs-4" }, "Menu")),
                React.createElement(Offcanvas.Body, { className: "justify-content-end" },
                    React.createElement(Nav, { className: "me-4 pe-4 open-sans fs-5 lh-lg" },
                        React.createElement(Nav.Link, { eventKey: "2", to: '/about', as: Link, className: "me-4 link-light" }, "About"),
                        React.createElement(Nav.Link, { eventKey: "3", to: '/projects', as: Link, className: "me-4 link-light" }, "Projects"),
                        React.createElement(Nav.Link, { eventKey: "4", to: '/contact', as: Link, className: "link-light" }, "Contact")))))));
};
export default NavigationBar;
